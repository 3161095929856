
import Vue from 'vue';

export default Vue.extend({
  name: 'FeatureFlag',

  props: {
    value: {
      type: Boolean,
      required: true,
    },
    isBlocked: {
      type: Boolean,
    },
    label: {
      type: String,
    },
    hint: {
      type: String,
    },
  },
});
