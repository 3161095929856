
import Vue from 'vue';

export default Vue.extend({
  name: 'FlagGroupIterator',

  props: {
    header: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
});
