
import Vue from 'vue';
import { mapMutations } from 'vuex';
import { Services } from '@/injectables/tokens';
import { AgencyConfigurationKey, AgencyConfigurationAbleKeys } from './types';
import { AgencyServiceContract } from '@/injectables';
import { Modules } from '@/store';
import { MutationTypes } from '@/store/agency/mutation';
import AppBar from '@/entities/app/base-app-bar.vue';
import { FlagGroupIterator as FlagGroup, FeatureFlag } from '@/entities/agency-dashboard';

export default Vue.extend({
  name: 'Agency',

  useInjectable: [Services.Agency],

  inject: ['showSnackbar'],

  components: {
    AppBar,
    FlagGroup,
    FeatureFlag,
  },

  data: (): { configuration: Record<string, boolean>; saveLoading: boolean } => ({
    configuration: {},
    saveLoading: false,
  }),

  computed: {
    userAgency(): string {
      return this.$store.state.auth.user?.Agency || '';
    },
    agency() {
      return this.$store.state.agency.currentAgencyInfo;
    },
    agencyInfoLoading() {
      return this.$store.state.agency.agencyInfoLoading;
    },
    othersKeys(): AgencyConfigurationKey[] {
      const list: AgencyConfigurationAbleKeys[] = [
        'congressionalDistrictAvailsEnabled',
        'dynamicColorsLayoutEnabled',
        'canHavePackages',
        'canCreateClient',
      ];
      return list;
    },
    submitKeys(): AgencyConfigurationKey[] {
      const list: AgencyConfigurationAbleKeys[] = ['placementEnabled', 'ioEmailEnabled'];
      return list;
    },
    reviewKeys(): AgencyConfigurationKey[] {
      const list: AgencyConfigurationAbleKeys[] = [
        'adminReviewEnabled',
        'adminIoOnly',
        'instantIOEnabled',
        'canGenerateContracts',
        'canSetPixelRequestForInstantCampaign',
        'canSetConversionTracking',
      ];
      return list;
    },
    nullOrNumberKey(): AgencyConfigurationKey {
      return 'audienceSegmentLimit';
    },
    hasChanges() {
      return Object.entries(this.configuration).filter(([, el]) => el !== undefined).length > 0;
    },
    flagDependencies() {
      return {
        adminIoOnly: 'adminReviewEnabled',
      };
    },
  },

  methods: {
    ...mapMutations(Modules.Agency, { setAgencyInfo: MutationTypes.SET_CURRENT_AGENCY_INFO }),
    getHint(key: AgencyConfigurationKey) {
      const dependency = this.flagDependencies[key];
      return `Can't be set without ${this.getReadableFlagName(dependency)}`;
    },
    setLocalUpdate(key, value) {
      const boolValue = Boolean(value);
      const dependency = this.relatedFlags(key);
      if (dependency && boolValue === false) {
        this.setLocalUpdate(dependency, false);
      }

      const cleanValue = Boolean(this.agency[key]) === boolValue || this.agency[key] === value;

      this.configuration = { ...this.configuration, [key]: cleanValue ? undefined : boolValue };
    },
    setNumberLocalUpdate(key, value, fromSwitch = false) {
      if (fromSwitch) {
        const cleanValue = this.agency[key] === value;
        const newValue = !value ? 0 : null;

        this.configuration = { ...this.configuration, [key]: cleanValue ? undefined : newValue };
      } else {
        const newValue = Number(value);
        const cleanValue = this.agency[key] === newValue;
        this.configuration = { ...this.configuration, [key]: cleanValue ? undefined : value };
      }
    },
    getReadableFlagName(key: string): string {
      const mapper: Record<AgencyConfigurationAbleKeys, string> = {
        ioEmailEnabled: 'Email service',
        placementEnabled: 'PIO',
        adminReviewEnabled: 'Admin approval',
        canHavePackages: 'Packages',
        canGenerateContracts: 'Contracts on Finalize',
        instantIOEnabled: 'All users Instant IO',
        adminIoOnly: 'Download IO for admin only',
        congressionalDistrictAvailsEnabled: 'Congressional Districts Geo type',
        canCreateClient: 'Create Advertiser',
        canSetPixelRequestForInstantCampaign: 'Pixel Request Toggle',
        canSetConversionTracking: 'Conversion Tracking',
        dynamicColorsLayoutEnabled: 'Dynamic Color for Layouts',
        audienceSegmentLimit: 'Limit Segments',
      };

      return mapper[key];
    },
    getValue(key: AgencyConfigurationKey): boolean {
      if (this.configuration[key] !== undefined) {
        return this.configuration[key];
      }
      return this.agency[key];
    },
    isBlockedBy(key) {
      const depended = this.flagDependencies[key];
      if (depended) {
        return !this.getValue(depended);
      }
      return false;
    },
    relatedFlags(key) {
      const shouldBeSwitched = Object.fromEntries(Object.entries(this.flagDependencies).map(([k, v]) => [v, k]));
      return shouldBeSwitched[key];
    },
    async saveChanges() {
      this.saveLoading = true;
      const { isErr, unwrap } = await (this[Services.Agency] as AgencyServiceContract).updateAgencyFlags(
        this.userAgency,
        this.configuration,
      );
      this.saveLoading = false;
      if (isErr()) {
        this.showSnackbar('Could not save changes at this time', 'error');
        return;
      }
      this.showSnackbar('Changes saved', 'success');
      this.setAgencyInfo(unwrap());
      this.configuration = {};
    },
  },
});
